<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="6">
        <mf-text-input v-model="button.title" :rules="[notEmptyRule]" :disabled="!editable" label="Título" outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-row class="mt-n6" no-gutters justify="end">
          <v-col cols="auto">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon small @click="newWindowRedirect('https://fontawesome.com/icons')">info</v-icon>
                </div>
              </template>
              <span>
                Consultar os ícones no fontawesome
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <mf-text-input v-model="button.icon" :rules="[notEmptyRule]" :disabled="!editable" label="Ícone" outlined />
      </v-col>
      <v-col cols="6">
        <mf-select v-model="button.icon_type" :rules="[notEmptyRule]" :disabled="!editable" :items="iconTypes" label="Tipo de icone" />
      </v-col>
      <v-col cols="6">
        <mf-text-input v-model="button.link" :rules="[notEmptyRule, validateInternalLink]" :disabled="!editable" label="Link" outlined />
      </v-col>
      <v-col cols="6">
        <mf-select v-model="button.type" :rules="[notEmptyRule]" :disabled="!editable" :items="iconTypes" label="Tipo" />
      </v-col>
      <v-col cols="6">
        <mf-select v-model="button.units" :disabled="!editable" item-value="_id" item-text="name" multiple :items="unit_items" label="Lojas"></mf-select>
      </v-col>
      <v-col cols="6">
        <v-row no-gutters align="center">
          <v-col cols="4" sm="12">
            <v-row justify="center">
              <v-col cols="auto">
                <v-checkbox v-model="button.club" label="Clube" color="primary" :disabled="!editable" hide-details></v-checkbox>
              </v-col>
              <v-col cols="auto">
                <v-checkbox v-model="button.ecommerce" label="E-commerce" :disabled="!editable" color="primary" hide-details></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div class="ml-4">
          A Ordem do Atalho define a prioridade na listagem de atalhos. Quanto menor o número mais à esquerda na listagem do app irá aparecer.
        </div>
        <v-text-field
          v-model="button.shortcutOrder"
          :disabled="!editable"
          :rules="[notEmptyRule, positiveIntegerRule]"
          label="Ordem Atalho"
          outlined
          class="mt-4"
        />
      </v-col>
      <v-col cols="6">
        <div class="ml-4">
          A Ordem do Gatilho estabelece a prioridade de listagem de gatilhos. Quanto menor o número mais à esquerda na listagem do app irá aparecer.
        </div>
        <v-text-field
          v-model="button.triggerOrder"
          :disabled="!editable"
          :rules="[notEmptyRule, positiveIntegerRule]"
          label="Ordem Gatilho"
          outlined
          class="mt-4"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <btn-card-actions
          v-if="!loading"
          class="pb-0"
          extra-button
          text-extra-button="Deletar Botão"
          iconExtraButton="mdi-delete"
          color-extra-button="error"
          :editable.sync="editable"
          @action="deleteButton"
          @save="saveHomeButton"
          @cancel="cancelRestoreHomeButtons"
        />
        <v-btn v-if="loading" class="mx-4 mt-4 mb-2" width="260" color="success">
          <v-icon class="mr-2">mdi-content-save</v-icon>
          Editando
          <v-progress-circular indeterminate color="#fff" :size="20" class="ml-2"></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { MUTATION_UPDATE_BUTTON, MUTATION_DELETE_BUTTON, QUERY_GET_UNITS } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  props: {
    button: {
      type: Object,
      required: true
    },
    accountId: {
      type: String,
      required: true
    },
    appType: {
      type: String,
      default: ''
    },
    index: {
      type: Number
    }
  },
  data: () => ({
    notEmptyRule: v => !!v || 'Campo obrigatório',
    positiveIntegerRule: v => (/^\d+$/.test(v) && parseInt(v, 10) >= 1) || 'Digite um número maior ou igual a 1',
    internalLinkRule: v => {
      return this.button.type !== 'INTERNAL' || (!v.includes('http') && !v.includes('www')) || 'Links internos não podem conter "http", "https" ou "www"'
    },
    valid: false,
    editable: false,
    loading: false,
    type: '',
    iconTypes: ['INTERNAL', 'EXTERNAL'],
    unit: '',
    button_type: '',
    unit_items: [],
    test: ''
  }),
  computed: {
    editIcon() {
      return this.appType === 'default' ? true : !this.editable
    }
  },
  apollo: {
    units: {
      query: QUERY_GET_UNITS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          account_id: this.$route.params.id
        }
      },
      async update({ units }) {
        this.unit_items = units
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountId: this.accountId
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    validateInput() {
      let variables = {
        title: this.button.title,
        link: this.button.link,
        icon: this.button.icon,
        flagText: this.button.flagText,
        units: this.button.units,
        subtitle: this.button.subtitle,
        ecommerce: this.button.ecommerce,
        club: this.button.club,
        type: this.button.type,
        shortcutOrder: this.button.shortcutOrder ? parseInt(this.button.shortcutOrder) : null,
        triggerOrder: this.button.triggerOrder ? parseInt(this.button.triggerOrder) : null,
        icon_type: this.button.icon_type
      }
      if (this.button.flagText !== '') {
        this.button.subtitle = ''
        variables.flagText = this.button.flagText
        variables.subtitle = ''
      }
      if (variables.flagText === '') delete variables.flagText
      if (variables.subtitle === '') delete variables.subtitle
      const input = Object.assign(
        {},
        { account_id: this.accountId },
        { app_type: this.appType },
        { button: variables },
        this.appType === 'default' ? { button_title: this.button.title } : { index: this.index }
      )
      return { input }
    },
    async saveHomeButton() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      this.loading = true
      let variables = this.validateInput()
      let context = {
        uri: this.$microservicesUrls['app_config']
      }
      if (this.appType === 'default') {
        context.headers = {
          authorization: localStorage.getItem('session_id'),
          accountid: this.$route.params.id
        }
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_BUTTON,
          variables,
          context
        })
        this.$alert({
          alert_message: `Botão ${this.button.title} alterado com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.loading = false
        this.editable = false
        this.$emit('refetch')
      } catch (err) {
        this.loading = false
        console.log(err.message)

        let alertOptions

        if (err.message.includes(`Shortcut order ${this.button.shortcutOrder} already exists`)) {
          alertOptions = {
            alert_message: `A ordem do atalho ${this.button.shortcutOrder} já está em uso`,
            alert_title: 'Erro!',
            alert_color: 'error',
            alert_icon: 'mdi-alert-circle'
          }
        } else if (err.message.includes(`Trigger order ${this.button.triggerOrder} already exists`)) {
          alertOptions = {
            alert_message: `A ordem do gatilho ${this.button.triggerOrder} já está em uso`,
            alert_title: 'Erro!',
            alert_color: 'error',
            alert_icon: 'mdi-alert-circle'
          }
        } else {
          alertOptions = {
            alert_message: `Falha ao alterar o botão ${this.button.title}`,
            alert_title: 'Erro!',
            alert_color: 'error',
            alert_icon: 'mdi-close-circle'
          }
        }
        this.$alert(alertOptions)
      }
    },
    cancelRestoreHomeButtons() {
      this.loading = false
      this.editable = false
      this.$emit('refetch')
    },
    async deleteButton() {
      const confirmation = await this.$confirm({
        title: 'Deletar o Botão',
        message: 'Deseja confimar deletar o botão?',
        confirmText: 'Continuar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirmation) return
      let variables = {
        input: {
          account_id: this.accountId,
          app_type: this.appType,
          button_id: this.button._id,
          button_title: this.button.title
        }
      }
      let context = {
        uri: this.$microservicesUrls['app_config']
      }
      if (this.appType === 'default') {
        context.headers = {
          authorization: localStorage.getItem('session_id'),
          accountid: this.$route.params.id
        }
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_BUTTON,
          variables,
          context
        })
        this.$alert({
          alert_message: `Botão removido com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$emit('refetch')
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: `Falha ao deletar o botão`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    newWindowRedirect(link) {
      return window.open(link, '_blank')
    },
    pushUnit(value) {
      this.unit = ''
      this.button.units = this.button.units ? this.button.units : []
      if (!this.button.units.includes(value)) return this.button.units.push(value)
      else
        return this.$alert({
          alert_message: `Loja já inserida`,
          alert_title: 'Aviso!',
          alert_color: 'secondary',
          alert_icon: 'mdi-close-circle'
        })
    },
    splitUnit(value) {
      const unitIndex = this.button.units.indexOf(value)
      this.button.units.splice(unitIndex, 1)
    },
    validateInternalLink(v) {
      if (this.button.type === 'INTERNAL' && v) {
        const urlPattern = /^(https?:\/\/|www\.|[\w-]+\.\w{2,})/i
        return !urlPattern.test(v) ? true : 'Links internos não podem conter URLs.'
      }
      return true
    }
  }
}
</script>
